import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  let twitterImage = null;
  if (post.frontmatter.featured_image?.twitter_image) {
    twitterImage = getSrc(post.frontmatter.featured_image.twitter_image);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        twitter_image={twitterImage}
      />
      <style>
        {`article figure figcaption {
          font-size: ${scale(-1 / 5).fontSize};
          line-height: ${scale(-1 / 5).lineHeight};
          margin-top: ${rhythm(1/2)};
          text-align: center;
        }
        
        article figure .gatsby-resp-image-background-image,
        article figure .gatsby-resp-image-image {
          border-radius: 4px;
        }`}
      </style>
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            <time dateTime={post.frontmatter.isoDate}>{post.frontmatter.date}</time>
          </p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li style={{
            maxWidth: '45%',
            textAlign: 'center',
          }}>
            {previous && (
              <Link to={previous.fields.slug} style={{ display: 'block', fontWeight: 'bold', boxShadow: 'none', paddingTop: '0.5rem', lineHeight: 1.25 }} rel="prev">
                { previous.frontmatter.featured_image ? (
                  <GatsbyImage
                    alt=""
                    image={{ ...previous.frontmatter.featured_image?.childImageSharp?.gatsbyImageData, aspectRatio: 16/9 }}
                    style={{ marginBottom: '0.5rem', width: '100%' }} />
                ) : (
                  <div style={{ paddingTop: '56.25%', backgroundColor: 'rgba(0,0,0,0.1)', marginBottom: '0.5rem' }}/>
                )}
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li style={{
            maxWidth: '45%',
            textAlign: 'center',
          }}>
            {next && (
              <Link to={next.fields.slug} style={{ display: 'block', fontWeight: 'bold', boxShadow: 'none', paddingTop: '0.5rem', lineHeight: 1.25 }} rel="next">
                { next.frontmatter.featured_image ? (
                  <GatsbyImage
                    alt=""
                    image={{ ...next.frontmatter.featured_image?.childImageSharp?.gatsbyImageData, aspectRatio: 16/9 }}
                    style={{ marginBottom: '0.5rem', width: '100%'}} />
                ) : (
                  <div style={{ paddingTop: '56.25%', backgroundColor: 'rgba(0,0,0,0.1)', marginBottom: '0.5rem' }}/>
                )}
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String!) {
  site {
    siteMetadata {
      title
    }
  }
  mdx(fields: {slug: {eq: $slug}}) {
    id
    excerpt(pruneLength: 160)
    body
    frontmatter {
      title
      isoDate: date
      date(formatString: "MMMM DD, YYYY")
      description
      featured_image {
        twitter_image: childImageSharp {
          gatsbyImageData(
            width: 876
            height: 438
            placeholder: BLURRED
            transformOptions: {cropFocus: ATTENTION}
            layout: FIXED
          )
        }
      }
    }
  }
}
`
